<template lang="html">
  <div>
    <v-sheet height="64">
      <v-toolbar
        flat
      >
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="next"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="setToday"
        >
          Hari ini
        </v-btn>
      </v-toolbar>
    </v-sheet>

    <!-- calendar -->
    <v-sheet height="600">
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="loadingCalendar"
      ></v-progress-linear>
      <v-calendar
        loading
        ref="calendar"
        color="primary"
        type="month"
        v-model="selectedDate"
        @change="updateRange"
      >
      <template v-slot:day="{date }">
        <v-sheet :class="getConditionalDate(date)" style="margin-top:-45px;" tile >
          <div class="d-flex full-width full-height align-end" style="height:100%">
            <template v-for="(ev, i) in customEvents">
               <v-tooltip top :key="i">
                 <template v-slot:activator="{ on, attrs }">
                 <v-avatar
                   :key="i"
                   v-if="ev.date == date"
                   :color="ev.color"
                   size="10"
                   class="ml-1 mb-1"
                   v-bind="attrs"
                   v-on="on"
                 />
               </template>
               <span>{{ev.name}}</span>
             </v-tooltip>
            </template>
          </div>
        </v-sheet>
    </template>
    </v-calendar>
    </v-sheet>

    <v-sheet>
      <h3 class="overline">Keterangan</h3>
      <p class="paragraph" style="font-size:12px">
        <b >Klik pada Angka tanggal untuk menuju ke tanggal aktifitas tertentu.</b> <br/>
        <span v-for="(ket,i) in ketLingkaran" :key="i">
          <v-avatar
            :color="ket.color"
            size="10"
            class="mx-2"
          />
          {{ket.text}}
        </span>
      </p>
    </v-sheet>

    <v-sheet style="background-color:#d9edf7">
      <v-btn class="info ma-3" v-if="$refs.calendar" @click="exportBackupAktivitas()"><v-icon left>mdi-file-excel</v-icon> Backup Aktivitas Ke Excel Bulan {{ $refs.calendar.title }} </v-btn><br>
      <p class="paragraph" style="font-size:12px;padding:0px 10px 10px !important">
        <strong><v-icon>mdi-alert-circle</v-icon></strong> Backuplah aktivitas pribadi secara berkala. Untuk berjaga-jaga, dan agar anda juga menyimpan data aktivitas anda sendiri
      </p>
    </v-sheet>

  </div>
</template>

<script>
import moment from 'moment'
import PelaksanaService from '@/services/PelaksanaService'

export default {
  props:{
    batasTanggal:String,
  },

  data(){
    return {
      selectedDate: '',
      startDate:null,
      endDate:null,
      loadingCalendar: false,
      customEvents:[],
      ketLingkaran:[
        {'text':'Jumlah disetujui', color:'success'},
        {'text':'Jumlah ditolak', color:'error'},
        {'text':'Jumlah aktivitas utama masih diproses', color:'info'},
        {'text':'Jumlah aktivitas tambahan masih diproses', color:'brown'},
        // {'text':'Penunjukan aktivitas kerja', color:'warning'},
        // {'text':'Penunjukan target kerja', color:'purple'},
      ]
    }
  },
  watch:{
    selectedDate(val){
      this.$emit('on-select-date', val)
    }
  },
  methods:{
    setToday() {
      this.selectedDate = ''
    },

    prev() {
      this.$refs.calendar.prev()
    },

    next() {
      this.$refs.calendar.next()
    },

    compareDate(a, b) {
      let dateA = new Date(a)
      let dateB = b ? new Date(b) : new Date()
      dateA.setHours(0, 0, 0, 0)
      dateB.setHours(0, 0, 0, 0)
      if (dateA.getTime() > dateB.getTime()) {
        return 1
      } else if (dateA.getTime() < dateB.getTime()) {
        return -1
      }
      return 0
    },

    getConditionalDate(date) {
      let allowed = this.compareDate(date, null) == -1 &&
        this.compareDate(date, this.batasTanggal) == 1 ||
        this.compareDate(date, this.batasTanggal) == 0 ||
        this.compareDate(date, null) == 0

      let idx = this.customEvents.findIndex( x => this.compareDate(x.date,date) == 0)
      let hasEvent = idx >= 0 ? true : false

      let today = this.compareDate(date, this.selectedDate) == 0

      return {
        "green lighten-3": hasEvent && allowed,
        "amber lighten-3": hasEvent && !allowed,
        'blue lighten-4': allowed,
        "orange lighten-1": today,
        'fill-height': true,
        'fill-width': true
      }
    },

    updateRange({start, end}){
      this.startDate = start.date
      this.endDate = end.date
      this.getCalendar(start.date,end.date)
    },

    getCalendar(startDate, endDate){
      let start = startDate ? startDate: this.startDate
      let end = endDate ? endDate: this.endDate
      this.customEvents = []
      this.loadingCalendar=true
      
      PelaksanaService.inputAktivitasGetCalendar({
        from: start,
        to: end
      }).then(response => {
        let events = []
        let customEvents = []
        let rawEvents = response.data.data

        for (let i = 0; i < rawEvents.length; i++) {
          let color = rawEvents[i].class.replace('event-', '')

          switch (color) {
            case 'brown':
              color = "brown"
              break;
            case 'important':
              color = "red"
              break;
            case 'special':
              color = "purple lighten-2"
              break;
          }
          customEvents.push({
            name: rawEvents[i].title,
            count: rawEvents[i].title.match(/\d+/) ? rawEvents[i].title.match(/\d+/)[0] : 0,
            date: rawEvents[i].tanggal,
            color: color
          })

        }
        this.customEvents = customEvents
      }).finally(()=>{
        this.loadingCalendar=false
      })
    },

    exportBackupAktivitas(){
      var current = this.$store.getters["user/current"]
      var nip = current.username //nip
      var year = current.year
      var role = current.role
      var bulan = this.$refs.calendar.parsedValue.month
      var urls = "https://kinerja.bandung.go.id/"+year+"/api/export-backup-input-aktivitas?nip="+nip+"&api=1&bulan="+bulan;
      window.open(urls,'_blank');
    }
  },
  created(){
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD')

    this.getCalendar(startOfMonth, endOfMonth)
  },

  mounted() {
    this.$refs.calendar.checkChange()
  },
}
</script>

<style lang="css" scoped>
</style>
